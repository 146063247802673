import React from "react";
import {FormattedMessage} from 'react-intl';

import {Card, CardBody, Col, Row} from "reactstrap";
import {MYMAPS_ID} from "../../../const";

export const EatingDinner: React.FC = () => (
  <>
    <h1><FormattedMessage id="generic.eatingdinner.title"/></h1>
    <Row>
      <Col lg="6">
        <p><FormattedMessage id="generic.eatingdinner.subtitle"/></p>
      </Col>
      <Col lg="6">
        <img
          alt="..."
          className="img-fluid"
          src={require('../../../assets/img/undraw/dinner.png')}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <p><FormattedMessage id="generic.eatingdinner.p1"/></p>
        <p><FormattedMessage id="generic.eatingdinner.p2"/></p>
        <p><FormattedMessage id="generic.eatingdinner.p3"/></p>
      </Col>
    </Row>
    <Row>
      <Col lg="6">
        <Card className="shadow shadow-sm mt-5">
          <CardBody>
            <div className="d-flex px-3">
              <div className="pl-4">
                <h5 className="title text-warning">
                  Resturante Galeón do Toural
                </h5>
                <p>Cantón do Toural, 4<br/>15705 - Santiago de Compostela</p>
                <p><span className="fa fa-calendar"/> <FormattedMessage
                  id="generic.eatingdinner.open"/> 10:00-1:30</p>
                <p><span className="fa fa-phone"/> (+34) 981 58 61 40</p>
                <p><span className="fa fa-globe"/> <a
                  href="http://galeontoural.com/" target="_blank"
                  rel="noopener noreferrer">galeontoural.com</a></p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="6">
        <Card className="shadow shadow-sm mt-5">
          <CardBody>
            <div className="d-flex px-3">
              <div className="pl-4">
                <h5 className="title text-warning">
                  Resturante Galeón do Toural
                </h5>
                <p>Rúa de San Lázaro, 90<br/>15707 Santiago de Compostela</p>
                <p><span className="fa fa-calendar"/> <FormattedMessage
                  id="generic.eatingdinner.open"/> 8:00-00:00</p>
                <p><span className="fa fa-phone"/> (+34) 696 87 61 40</p>
                <p><span className="fa fa-globe"/> <a
                  href="https://goo.gl/maps/Jia8AjLgoWQVz8LD8/" target="_blank"
                  rel="noopener noreferrer">Google Maps</a></p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row className="mt-5">
      <Col>
        <h3><FormattedMessage id="generic.eatingdinner.check"/></h3>
        <Card className="shadow border-0">
          <CardBody className="py-0 px-0 pr-0 pl-0 pt-0 pb-0" style={{height: 512}}>
            <iframe
              title="map"
              src={`https://www.google.com/maps/d/u/0/embed?mid=${MYMAPS_ID}&z=14`}
              width="100%"
              height="512"
              style={{border: "none"}}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </>
);
