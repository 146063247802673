import React, {useRef} from "react";
import ReactGA from "react-ga4";
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";

import {Alert, Badge, Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {AppRoute, AppRouteTitles, DefaultLanguage} from "../../const";
import {Route} from "../../modules/i18n";
import {HorizontalSponsors} from "../../modules/layout";


const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
export const Join: React.FC = () => {
  const {formatMessage, locale} = useIntl();
  const sec = useRef(null);
  const executeScroll = () => scrollToRef(sec);

  // Conversión de inicio de inscripción
  ReactGA.gtag('event', 'conversion', {
    'send_to': 'AW-665846206/cMGvCJm02okYEL6DwL0C'
  });

  // let iFrameResizer = require('iframe-resizer/js/iframeResizer');

  let baseurl: string = "https://www.rockthesport.com/es/evento/santyago10k/inscripcion/selecciona-tarifa";

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement !== null)
      document.scrollingElement.scrollTop = 0;

    let lang = locale;
    if (lang === DefaultLanguage)
      lang = "gl";

    switch (lang) {
      case "en":
        baseurl = "https://www.rockthesport.com/en/event/santyago10k/registration/select-rate";
        break;

      default:
        baseurl = "https://www.rockthesport.com/es/evento/santyago10k/inscripcion/selecciona-tarifa";
        break;
    }

    if (localStorage.getItem("opened") !== "1") {
      window.open(baseurl, "_blank");
      localStorage.setItem("opened", "1");
    }

    /* const iframeurl = baseurl + "?frm=0&men=0&tit=1";

    const frame = document.getElementById('iframe') as HTMLIFrameElement;

    iFrameResizer({
      log: false,
      enablePublicMethods: true,
      checkOrigin: false,
      autoResize: true,
      minHeight: 700,
      maxHeight: 750,
      scrolling: true
    }, frame);
    frame.src = iframeurl; */
  }, [/* iFrameResizer, */locale,]);

  const openenrol = () => window.open(baseurl, "_blank");

  return (
    <>
      <Helmet>
        <title>{formatMessage({id: "before.join.title"})} | SantYaGo10K 2023</title>
      </Helmet>
      <main>
        <div className="position-relative">
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-orange">
              <span/> <span/> <span/> <span/> <span/>
              <span/> <span/> <span/> <span/>
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      {formatMessage({id: "before.join.title"})}
                    </h1>
                    <p className="lead text-white">
                      {formatMessage({id: "before.join.subtitle"})}
                    </p>
                    {/* TODO: After Opening */}
                    <Button
                      className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                      color="default"
                      onClick={openenrol}
                    >
                        <span className="btn-inner--icon mr-1">
                            <i className="fa fa-hand-o-right"/>
                        </span>
                      <span className="btn-inner--text">
                            {formatMessage({id: "before.join.now"})}
                        </span>
                    </Button>
                    <Button
                      className="btn-icon mb-3 mb-sm-0 ml-1"
                      color="default"
                      to={Route(AppRoute.Track)}
                      tag={Link}
                    >
                                                <span className="btn-inner--icon mr-1">
                                                    <i className="fa fa-map-o"/>
                                                </span>
                      <span className="btn-inner--text">
                                                    {formatMessage({id: AppRouteTitles.get(AppRoute.Track)})}
                                                </span>
                    </Button>
                  </Col>
                  <Col lg="6">
                    <img
                      alt="..."
                      className="img-fluid rounded shadow-lg"
                      src={require('../../assets/img/race/pages/join.jpg')}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
              >
                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
          <section className="section pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-4">
                          <div
                            className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="fa fa-step-forward"/>
                          </div>
                          <h6 className="text-default text-uppercase">
                            {formatMessage({id: 'before.join.regular'})}
                            <span style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                            <Badge color="warning" pill className="mr-1">
                              <span className="fa fa-money"/> 10.00 €
                            </Badge>
                          </h6>
                          <p className="description">
                            <ul className="list-unstyled">
                              <li className="py-1">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Badge
                                      className="badge-circle mr-3"
                                      color="success"
                                    >
                                      <i className="fa fa-play"/>
                                    </Badge>
                                  </div>
                                  <div>
                                    <h6 className="mb-0">
                                      <span className="fa fa-calendar"/>
                                      <span
                                        style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                                      01/03/2023
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li className="py-1">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Badge
                                      className="badge-circle mr-3"
                                      color="danger"
                                    >
                                      <i className="fa fa-pause"/>
                                    </Badge>
                                  </div>
                                  <div>
                                    <h6 className="mb-0">
                                      <span className="fa fa-calendar"/>
                                      <span
                                        style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                                      02/04/2023
                                    </h6>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            {formatMessage({id: 'before.join.regular.description'})}
                          </p>
                          {/* TODO: After Opening First Phase */}
                          {/*}<Button
                            className="btn-icon mb-3 mb-sm-0 ml-1"
                            color="default"
                            onClick={executeScroll}
                          >
                           <span className="btn-inner--icon mr-1">
                              <i className="fa fa-caret-down"/>
                           </span>
                            <span className="btn-inner--text">
                              {formatMessage({id: "before.join.now"})}
                           </span>
                          </Button>{*/}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-4">
                          <div
                            className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="fa fa-fast-forward"/>
                          </div>
                          <h6 className="text-default text-uppercase">
                            {formatMessage({id: 'before.join.lastcall'})}
                            <span style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                            <Badge color="warning" pill className="mr-1">
                              <span className="fa fa-money"/> +13.00 €
                            </Badge>
                          </h6>
                          <p className="description">
                            <ul className="list-unstyled">
                              <li className="py-1">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Badge
                                      className="badge-circle mr-3"
                                      color="success"
                                    >
                                      <i className="fa fa-play"/>
                                    </Badge>
                                  </div>
                                  <div>
                                    <h6 className="mb-0">
                                      <span className="fa fa-calendar"/>
                                      <span
                                        style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                                      03/04/2023
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li className="py-1">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Badge
                                      className="badge-circle mr-3"
                                      color="danger"
                                    >
                                      <i className="fa fa-pause"/>
                                    </Badge>
                                  </div>
                                  <div>
                                    <h6 className="mb-0">
                                      <span className="fa fa-calendar"/>
                                      <span
                                        style={{userSelect: "none"}}>&nbsp;&nbsp;&nbsp;</span>
                                      23/04/2023
                                    </h6>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            {formatMessage({id: 'before.join.lastcall.description'})}
                          </p>
                          {/* TODO: After Opening Second Phase */}
                          {/*}<Button
                            className="btn-icon mb-3 mb-sm-0 ml-1"
                            color="default"
                            onClick={executeScroll}
                          >
                           <span className="btn-inner--icon mr-1">
                              <i className="fa fa-caret-down"/>
                           </span>
                           <span className="btn-inner--text">
                              {formatMessage({id: "before.join.now"})}
                           </span>
                          </Button>{*/}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section" ref={sec}>
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-2" md="4">
                  <img
                    alt="..."
                    className="img-fluid floating mb-3"
                    src={require("../../assets/img/undraw/jogging.png")}
                  />
                  <h4><span className="fa fa-bell-o"/> {formatMessage({id: 'before.join.important'})}
                  </h4>
                  <p>{formatMessage({id: 'before.join.important.finish'})}</p>
                  <p>{formatMessage({id: 'before.join.important.price'})}</p>
                </Col>
                <Col className="order-1" md="8">
                  <div className="pr-md-5">
                    {/* TODO: Pre-Registrations */}
                    {/*}<Alert className="alert-default">
                      <strong>Oops!</strong> {formatMessage({id: 'before.join.not'})}
                    </Alert>{*/}
                    {/* TODO: During-Registrations */}
                    <Alert className="alert-default">
                      <FormattedMessage
                        id="before.join.group"
                        values={{
                          a: (chunks: React.ReactNode) => (
                            <a
                              className="text-white"
                              href={"mailto:" + chunks}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <u>{chunks}</u>
                            </a>
                          ),
                        }}
                      />
                    </Alert>
                    {/* TODO: Post-Registrations */}
                    {/*}<img
                      alt="..."
                      className="img-fluid floating mb-3"
                      src={require("../../assets/img/undraw/no_time.png")}
                    />{*/}

                    <a href={baseurl} target="_blank" rel="noopener noreferrer">
                      <Button
                        className="btn-lg mt-3"
                        color="warning"
                        style={{width: "100%"}}
                        onClick={e => {
                          e.preventDefault();
                          window.open(baseurl, "_blank");
                        }}
                      >
                        {formatMessage({id: 'before.join.link'})}
                      </Button>
                    </a>

                    {/*}<iframe
                      className="shadow--hover"
                      style={{width: "100%", border: "none"}}
                      scrolling="no"
                      id="iframe"
                      title="join"
                    >
                      <a
                        href="https://www.rockthesport.com/es/evento/santyago10k/inscripcion/selecciona-tarifa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.rockthesport.com/es/evento/santyago10k/inscripcion/selecciona-tarifa
                      </a>
                    </iframe>{*/}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody>
                      <h3>{formatMessage({id: "before.join.physical"})}</h3>
                      <FormattedMessage
                        id="before.join.physical.description"
                        values={{
                          p: (chunks: React.ReactNode) => (
                            <p>{chunks}</p>
                          ),
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody>
                      <h3>{formatMessage({id: "before.join.discount"})}</h3>
                      <FormattedMessage
                        id="before.join.discount.description"
                        values={{
                          p: (chunks: React.ReactNode) => (
                            <p>{chunks}</p>
                          ),
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <HorizontalSponsors/>
        </div>
      </main>
    </>
  );
};

export default Join;
