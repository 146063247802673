import React from "react";
import {Helmet} from 'react-helmet';

import {
    Container,
    Row,
    Col,
} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {VerticalSponsors} from "../../modules/layout";
import YouTube, {Options} from "react-youtube";


export const Visiting: React.FC = () => {
    const {formatMessage} = useIntl();
    const yt: Options = {
        width: '100%',
        height: '256px',
        playerVars: {
            autoplay: 0
        }
    };

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    });

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "after.visiting.title"})} | SantYaGo10K 2023</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 bg-gradient-green">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "after.visiting.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "after.visiting.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/visiting.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    {/* 1st Hero Variation */}
                    <section className="section section-sm">
                        <Container>
                            <Row>
                                <Col lg="8">
                                    <h1><FormattedMessage id="after.visiting.heading"/></h1>
                                    <Row>
                                        <Col lg="6">
                                            <img
                                                alt="..."
                                                className="img-fluid"
                                                src={require('../../assets/img/undraw/visiting.png')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p><FormattedMessage id="after.visiting.p1"/></p>
                                            <ul>
                                                <li>
                                                    <a
                                                        href="http://santiagoturismo.com/que-facer"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >santiagoturismo.com/que-facer</a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="http://www.santiagoturismo.com/santiago-en-1-2-e-3-dias"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >santiagoturismo.com/santiago-en-1-2-e-3-dias</a>
                                                </li>
                                            </ul>
                                            <p><FormattedMessage id="after.visiting.p2"/></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <YouTube
                                                videoId="L3a6GuXLJII"
                                                className="shadow"
                                                opts={yt}
                                            />
                                        </Col>

                                        <Col lg="6">
                                            <YouTube
                                                videoId="NlRjDghzfEo"
                                                className="shadow"
                                                opts={yt}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4">
                                    <VerticalSponsors/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Visiting;
