import React from "react";
import {Helmet} from 'react-helmet';

import {Col, Container, Row} from "reactstrap";
import {useIntl} from "react-intl";
import {HorizontalSponsors} from "../../modules/layout";
import {DefaultLanguage} from "../../const";


export const Results: React.FC = () => {
    const {formatMessage, locale} = useIntl();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;

        switch (locale) {
            case DefaultLanguage:
            case "es":
                window.location.replace("https://my.raceresult.com/242542/results?lang=es");
                break;

            default:
                window.location.replace("https://my.raceresult.com/242542/results?lang=en");
                break;
        }
    }, [locale,]);


    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "after.results.title"})} | SantYaGo10K 2023</title>
            </Helmet>
            <main>
                <div className="position-relative">
                    {/* shape Hero */}
                    <section className="section section-lg section-shaped">
                        <div className="shape shape-style-1 shape-orange">
                            <span/> <span/> <span/> <span/> <span/>
                            <span/> <span/> <span/> <span/>
                        </div>
                        <Container className="py-lg-md d-flex">
                            <div className="col px-0">
                                <Row>
                                    <Col lg="6">
                                        <h1 className="display-3 text-white">
                                            {formatMessage({id: "after.results.title"})}
                                        </h1>
                                        <p className="lead text-white">
                                            {formatMessage({id: "after.results.subtitle"})}
                                        </p>
                                    </Col>
                                    <Col lg="6">
                                        <img
                                            alt="..."
                                            className="img-fluid rounded shadow-lg"
                                            src={require('../../assets/img/race/pages/results.jpg')}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
                                version="1.1" viewBox="0 0 2560 100" x="0" y="0"
                            >
                                <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                            </svg>
                        </div>
                    </section>
                    <section className="section">
                        <Container>
                            <Row className="row-grid align-items-center">
                                <Col span={12}>
                                    <div className="pr-md-5">
                                        <iframe
                                            className="shadow--hover"
                                            style={{width: "100%", border: "none", height: 920}}
                                            id="iframe"
                                            title="results"
                                            src="/results.html"
                                        >
                                            <a href="/results.html" target="_blank" rel="noopener noreferrer">
                                                /results.html
                                            </a>
                                        </iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <HorizontalSponsors/>
                </div>
            </main>
        </>
    );
};

export default Results;
