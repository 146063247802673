interface PressItem {
    title: string,
    date: number,
    provider: string,
    source: string
}

export const Press: PressItem[] = [
    {
        title: "Aberto o prazo de inscrición para a VII edición da carreira nocturna SantYaGo 10K o vindeiro 29 de abril",
        date: 1677625200,
        provider: "concello",
        source: "http://santiagodecompostela.gal/hoxe/nova.php?lg=gal&id_nova=22270"
    },
    {
        title: "Cortes de tráfico e modificacións no transporte urbano con motivo da carreira nocturna SantYaGo10K",
        date: 1682460000,
        provider: "ecg",
        source: "https://www.elcorreogallego.es/santiago/2023/04/26/cortes-trafico-modificacions-transporte-urbano-86516753.html"
    },
    {
        title: "Más de mil inscritos respaldan la Sant Yago 10K",
        date: 1682632800,
        provider: "lvdg",
        source: "https://www.lavozdegalicia.es/noticia/santiago/2023/04/29/mil-inscritos-respaldan-sant-yago-10k/0003_202304S29P429911.htm"
    },
    {
        title: "Todo listo para la SantYaGo10K que arranca a las diez de esta noche con cortes de tráfico y modificaciones en el transporte urbano",
        date: 1682719200,
        provider: "ecg",
        source: "https://www.elcorreogallego.es/deportes/2023/04/29/listo-santyago10k-arranca-diez-noche-86641424.html"
    },
    {
        title: "Jorge Puig y María Cedrón ganan con total autoridad la Sant Yago 10K",
        date: 1682805600,
        provider: "lvdg",
        source: "https://www.lavozdegalicia.es/noticia/santiago/santiago/2023/04/30/jorge-puig-maria-cedron-ganan-total-autoridad-sant-yago-10k/0003_202304S30P48994.htm"
    },
    {
        title: "María Cedrón y Jorge Puig, vencedores de la SantYago10K",
        date: 1682805600,
        provider: "ecg",
        source: "https://www.elcorreogallego.es/deportes/2023/04/30/maria-cedron-jorge-puig-vencedores-86687778.html"
    },
];
